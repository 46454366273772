import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
// project imports
import NavigationScroll from "./layout/NavigationScroll";
import Routes from "./routes";
import themes from "./themes";
import SnackBar from "./components/SnackBar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Pusher from "pusher-js";
import audioUrl from "./assets/sound/notification.mp3";
import { useEffect, useState } from "react";
import { socket } from './socket/socket';
function App() {
  // const [isPlaying, setIsPlaying] = useState(false);
  // var pusher = new Pusher("20ea7f3e6a401a074bd6", {
  //   cluster: "ap2",
  // });
  // let audioContext = null;
  // let audioSource = null;

  // var channel = pusher.subscribe("admin-order");
  // channel.bind("message", async function (data) {
  //   try {
  //     // Fetch the audio file
  //     const response = await fetch(audioUrl);

  //     // Convert the response to an ArrayBuffer
  //     const buffer = await response.arrayBuffer();

  //     // Create an AudioContext
  //     audioContext = new (window.AudioContext || window.webkitAudioContext)();

  //     // Decode the audio data
  //     const audioBuffer = await audioContext.decodeAudioData(buffer);

  //     // Create a BufferSource node and connect it to the destination (speakers)
  //     audioSource = audioContext.createBufferSource();
  //     audioSource.buffer = audioBuffer;
  //     audioSource.connect(audioContext.destination);

  //     // Set up an event listener for when the audio ends
  //     audioSource.onended = () => {
  //       setIsPlaying(false);
  //     };
  //     console.log(isPlaying);

  //     // Start playing the audio
  //     audioSource.start();
  //   } catch (error) {
  //     console.error("Error loading or playing audio:", error);
  //   }
  // });
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);

  useEffect(() => {
    function onConnect() {
      console.log("connected");
      setIsConnected(true);
    }

    function onDisconnect() {
      console.log("disconnected");

      setIsConnected(false);
    }

    function onFooEvent(value) {
      setFooEvents((previous) => [...previous, value]);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("foo", onFooEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("foo", onFooEvent);
    };
  }, []);
  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider
          theme={themes({
            borderRadius: 12,
            defaultId: "default",
            fontFamily: "'Roboto', sans-serif",
            isOpen: [],
            opened: true,
          })}
        >
          {/* <ThemeProvider> */}
          <CssBaseline />
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
        </ThemeProvider>
        <SnackBar />
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}

export default App;

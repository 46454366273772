import { lazy } from "react";
import Loadable from "../ui-component/Loadable";
const AuthGuard = Loadable(
  lazy(() => import("../views/pages/authentication/AuthGuard"))
);
const MainLayout = Loadable(lazy(() => import("../layout/MainLayout")));
const DashboardDefault = Loadable(
  lazy(() => import("../views/dashboard/Default"))
);
const MainOrders = Loadable(lazy(() => import("../views/order/MainOrders")));
const OrderDetails = Loadable(
  lazy(() => import("../views/order/OrderDetails"))
);
 
//store
const AddStore = Loadable(lazy(() => import("../views/store/AddStore")));
const ListStore = Loadable(lazy(() => import("../views/store/ListStore")));
const ChangePassword = Loadable(lazy(() => import("../views/pages/authentication/ChangePassword")));
const StoreSettings = Loadable(
  lazy(() => import("../views/store/StoreSettings"))
);
const StoreProfile = Loadable(
  lazy(() => import("../views/store/StoreProfile"))
);
const Addon = Loadable(
  lazy(() => import("../views/store/addons/Addon"))
);
const MenuOptions = Loadable(
  lazy(() => import("../views/store/options/MenuOptions"))
);
const Category = Loadable(
  lazy(() => import("../views/store/category/Category"))
);
const Menu = Loadable(
  lazy(() => import("../views/store/menu/Menu"))
);
const MenuUpload = Loadable(
  lazy(() => import("../views/store/adminAction/MenuUpload"))
);
 
//order
const ReviewOrder = Loadable(
  lazy(() => import("../views/order/review/ReviewOrder"))
);
const DeliveryBoyAdd = Loadable(
  lazy(() => import("../views/deliveryBoy/DeliveryBoyAdd"))
);
const CustomerService = Loadable(
  lazy(() => import("../views/customerService/CustomerService"))
);
 
//offer
const ComboOffer = Loadable(
  lazy(() => import("../views/offer/ComboOffer"))
);
const DiscountOffer = Loadable(
  lazy(() => import("../views/offer/DiscountOffer"))
);
const StoreOffer = Loadable(
  lazy(() => import("../views/offer/StoreOffer"))
);
const CategoryOffer = Loadable(
  lazy(() => import("../views/offer/CategoryOffer"))
);
const ItemOffer = Loadable(
  lazy(() => import("../views/offer/ItemOffer"))
);
const NewCustomerCode = Loadable(
  lazy(() => import("../views/offer/NewCustomerCode"))
);
 
//performance Dialy
const DialyPerformance = Loadable(
  lazy(() => import("../views/report/performance/DialyPerformance"))
);
const WeeklyPerformance = Loadable(
  lazy(() => import("../views/report/performance/WeeklyPerformance"))
);
const MonthlyPerformance = Loadable(
  lazy(() => import("../views/report/performance/MonthlyPerformance"))
);
 
//Summary
const DialySummary = Loadable(
  lazy(() => import("../views/report/summary/DialySummary"))
);
const WeeklySummary = Loadable(
  lazy(() => import("../views/report/summary/WeeklySummary"))
);
const MonthlySummary = Loadable(
  lazy(() => import("../views/report/summary/MonthlySummary"))
);
//invoice
const MonthlyInvoice = Loadable(
  lazy(() => import("../views/report/MonthlyInvoice"))
);
 
//notification
const UsetNotification = Loadable(
  lazy(() => import("../views/userNotification/userNotification"))
);
 
//complaits
const Complaints = Loadable(
  lazy(() => import("../views/feedback/Complaints"))
);
 
const CategoryTypes = Loadable(
  lazy(() => import("../views/settings/categoryTypes/CategoryTypes"))
);
const StoreCusins = Loadable(
  lazy(() => import("../views/settings/cusins/StoreCusins"))
);
 
const ViewMenu = Loadable(
  lazy(() => import("../views/store/menu/ViewMenu"))
);
 
//audit trailas
const AuditTrails = Loadable(
  lazy(() => import("../views/audit/AuditTrails"))
);
 
//acount settings
const BranchPartner = Loadable(
  lazy(() => import("../views/accounts/branchPartner/BranchPartner"))
);
 
const CustomerCareAdmin = Loadable(
  lazy(() => import("../views/accounts/customercare/CustomerCareAdmin"))
);
const CustomerCareExecutive = Loadable(
  lazy(() => import("../views/accounts/customercare/CustomerCareExecutive"))
);
 
const AccountantAdmin = Loadable(
  lazy(() => import("../views/accounts/accountants/AccountantAdmin"))
);
const AccountsManager = Loadable(
  lazy(() => import("../views/accounts/accountants/AccountsManager"))
);
const AccountsExecutive = Loadable(
  lazy(() => import("../views/accounts/accountants/AccountsExecutive"))
);
const MarketingAdmin = Loadable(
  lazy(() => import("../views/accounts/marketing/MarketingAdmin"))
);
const MarketingExecutive = Loadable(
  lazy(() => import("../views/accounts/marketing/MarketingExecutive"))
);
const OfficeStaff = Loadable(
  lazy(() => import("../views/accounts/officeStaff/OfficeStaff"))
);
 
const Area = Loadable(
  lazy(() => import("../views/settings/area/Area"))
);
const Role = Loadable(
  lazy(() => import("../views/settings/role/Role"))
);
const Permission = Loadable(
  lazy(() => import("../views/settings/role/rolePermission"))
);
const StoreFirstOrder = Loadable(
  lazy(() => import("../views/offer/StoreFirstOrder/StoreFirstOrder"))
);
const CratisRegister = Loadable(
  lazy(() => import("../views/settings/cratis/CratisRegister"))
);
 
//online order reports
const OnlineOrder = Loadable(
  lazy(() => import("../views/report/onlineOrder/OnlineOrder"))
);
 
//pending Settilement
const PendingSettilement = Loadable(
  lazy(() => import("../views/report/settlement/PendingSettilement"))
);
 
//create
const CreateInvoice = Loadable(
  lazy(() => import("../views/report/settlement/CreateInvoice"))
);
const ListInvoice = Loadable(
  lazy(() => import("../views/report/settlement/ListInvoice"))
);
const MachineStatus = Loadable(
  lazy(() => import("../views/settings/machine/machineStatus"))
);
 
 
const Machine = Loadable(
  lazy(() => import("../views/settings/machine/machines"))
);
const UserUpdate = Loadable(
  lazy(() => import("../views/pages/authentication/userupdate"))
);
 
const CreateTaxInvoice =  Loadable(
  lazy(() => import("../views/taxInvoice/create"))
);
const ViewTaxInvoice =  Loadable(
  lazy(() => import("../views/taxInvoice/view"))
);
const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/dashboard",
      element: <DashboardDefault />,
    },
    {
      path: "/store/list",
      element: <ListStore />,
      // children: [
      //   {
      //     path: "add",
      //     element: <AddStore />,
      //   },
      //   {
      //     path: "list",
      //     element: <ListStore />,
      //   },
      //   {
      //     path: "settings",
      //     element: <StoreSettings />,
      //   },
      //   {
      //     path: "storeProfile/:id",
      //     element: <StoreProfile />,
      //   },
      //   {
      //     path: "storeProfile/addons/:id",
      //     element: <Addon />,
      //   },
      //   {
      //     path: "storeProfile/options/:id",
      //     element: <MenuOptions />,
      //   },
      //   {
      //     path: "storeProfile/category/:id",
      //     element: <Category />,
      //   },
      //   {
      //     path: "storeProfile/menu/:id",
      //     element: <Menu />,
      //   },
      //   {
      //     path: "storeProfile/viewMenu/:storeId/:catID",
      //     element: <ViewMenu />,
      //   },
      //   {
      //     path: "menuUpload",
      //     element: <MenuUpload />,
      //   },
      // ],
    },
    // {
    //   path: "/deliveryBoy",
    //   children: [
    //     {
    //       path: "add",
    //       element: <DeliveryBoyAdd />,
    //     },
    //   ],
    // },
    // {
    //   path: "/settings",
    //   children: [
    //     {
    //       path: "category-types",
    //       element: <CategoryTypes />,
    //     },
    //     {
    //       path: "cusins",
    //       element: <StoreCusins />,
    //     },
    //     {
    //       path: "area",
    //       element: <Area />,
    //     },
    //     {
    //       path: "cratis",
    //       element: <CratisRegister />,
    //     },
    //     {
    //       path: "role",
    //       element: <Role />,
    //     },
    //     {
    //       path: "permission",
    //       element: <Permission />,
    //     },
    //   ],
    // },
    // {
    //   path: "/customerService",
    //   element: <CustomerService />,
    // },
    // {
    //   path: "/branch",
    //   children: [
    //     {
    //       path: "order",
    //       element: <MainOrders />,
    //     },
    //   ],
    // },
    // {
    //   path: "/order",
    //   children: [
    //     {
    //       path: "view",
    //       element: <MainOrders />,
    //     },
    //     {
    //       path: "review",
    //       element: <ReviewOrder />,
    //     },
    //     {
    //       path: "orderDetails/:id",
    //       element: <OrderDetails />,
    //     },
    //     {
    //       path: "onlineOrder",
    //       element: <OrderDetails />,
    //     },
    //   ],
    // },
    // {
    //   path: "/offer",
    //   children: [
    //     {
    //       path: "combo_offer",
    //       element: <ComboOffer />,
    //     },
    //     {
    //       path: "discount",
    //       element: <DiscountOffer />,
    //     },
    //     {
    //       path: "store",
    //       element: <StoreOffer />,
    //     },
    //     {
    //       path: "category",
    //       element: <CategoryOffer />,
    //     },
    //     {
    //       path: "item",
    //       element: <ItemOffer />,
    //     },
    //     {
    //       path: "newCustomer",
    //       element: <NewCustomerCode />,
    //     },
    //     {
    //       path: "StoreNewOrderOffer",
    //       element: <StoreFirstOrder />,
    //     },
    //   ],
    // },
    {
      path: "/report",
      children: [
        {
          path: "performance_report",
          children: [
            {
              path: "dialy",
              element: <DialyPerformance />,
            },
            {
              path: "weekly",
              element: <WeeklyPerformance />,
            },
            {
              path: "monthly",
              element: <MonthlyPerformance />,
            },
          ]
        },
        {
          path: "summary_report",
          children: [
            {
              path: "dialy",
              element: <DialySummary />,
            },
            {
              path: "weekly",
              element: <WeeklySummary />,
            },
            {
              path: "monthly",
              element: <MonthlySummary />,
            },
          ]
        },
        {
          path: "pending-settlement",
          element: <PendingSettilement />,
        },
        {
          path: "list-invoice",
          element: <ListInvoice />,
        },
        {
          path: "invoice",
          element: <MonthlyInvoice />,
        },
        {
          path: "onlineOrder",
          element: <OnlineOrder />,
        },
        {
          path: "create-invoice",
          element: <CreateInvoice />,
        },
      ],
    },
    // {
    //   path: "/notification",
    //   // element: <Notification />,
    //   children: [
    //     {
    //       path: "customer",
    //       element: <Notification />,
    //     },
    //     {
    //       path: "area",
    //       element: <Notification />,
    //     },
    //     {
    //       path: "scheduled",
    //       element: <Notification />,
    //     },]
    // },
    // {
    //   path: "/feedback",
    //   element: <Complaints />,
    // },
    {
      path: "/userUpdate",
      element: <UserUpdate />,
    },
    {
      path: "/profile",
      element: <ChangePassword />,
    },
    {
      path: "/audit-tails",
      element: <AuditTrails />,
    },
    {
      path: "/notification/view",
      element: <UsetNotification />,
    },
    {
      path: "/taxinvoice",
      children: [
        {
          path: "view",
          element: <ViewTaxInvoice />,
        },
        {
          path: "create",
          element: <CreateTaxInvoice />,
        },
      ],
    },
    // {
    //   path: "/accounts",
    //   children: [
    //     {
    //       path: "branch-partner",
    //       element: <BranchPartner />,
    //     },
    //     {
    //       path: "custumercare-admin",
    //       element: <CustomerCareAdmin />,
    //     },
    //     {
    //       path: "custumercare-executive",
    //       element: <CustomerCareExecutive />,
    //     },
    //     {
    //       path: "accountant-admin",
    //       element: <AccountantAdmin />,
    //     },
    //     {
    //       path: "accounts-manager",
    //       element: <AccountsManager />,
    //     },
    //     {
    //       path: "accounts-executive",
    //       element: <AccountsExecutive />,
    //     },
    //     {
    //       path: "marketing-admin",
    //       element: <MarketingAdmin />,
    //     },
    //     {
    //       path: "marketing-executive",
    //       element: <MarketingExecutive />,
    //     },
    //     {
    //       path: "office-staff",
    //       element: <OfficeStaff />,
    //     }
    //   ],
    // },
    // {
    //   path: "/settings",
    //   children: [
    //     {
    //       path: "status",
    //       element: <MachineStatus/>,
    //     },
    //     {
    //       path: "machine",
    //       element: <Machine />,
    //     },
    //   ]
    // },
  ],
};
 
export default MainRoutes;